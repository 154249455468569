import React from 'react';
/* import '../App.css'; */
/* import { Link } from 'react-router-dom'; */

function Header() {
    return (<div className='container' style={{ opacity: '0.9', paddingLeft: '20px', paddingRight: '20px'}}>
        <section className="bg-light" id="politicas">
            <div className="container px-4" style={{ marginBottom: '20px', opacity: '1' }}>
                <div className="row gx-4 justify-content-center">
                    <div className="col-lg-8">
                        <h2 style={{ color: 'black' }}>DESPACHOS</h2>
                        <p className="lead" style={{ textAlign: 'justify', color: 'black' }}>Los "errores de dirección una vez despachado" o "cambios de dirección después del despacho", son para las empresas de despacho REPROGRAMACION DE DESPACHO lo que corresponde a un nuevo cobro extra. Este cobro extra no es responsabilidad de la tienda TOYTEK, es por esto que probablemente se le solicite RUT y a la vez se le pida confirmar su dirección antes del despacho, así evitamos malos entendidos. Tambien asumimos la responsabilidad y preocupación del Cliente al momento de registrar sus datos como la dirección de despacho, redactar correctamente o bien informar vía correo el cambio de dirección a enviar.</p>
                        <ul>
                            <li>Despachos se realizan una vez pagado el producto.</li>
                            <li>Los despachos son generalmente STARKEN.</li>
                            <li>No se despacha en sobre, sólo cuando es factible.</li>
                            <li>Se declarará valor real y el tope de devolución lo coloca la empresa de transportes al igual que el pago de la devolución en el caso de extravío o robo después del despacho.</li>
                            <li>Los despachos generalmente son de Lunes a Viernes.</li>
                            <li>Los horarios de despacho serán después de las 14:00 horas.</li>
                            <li>LOS DESPACHOS SE REALIZAN DESDE VALPARAÍSO, V REGIÓN, CHILE.</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="container px-4" style={{ marginBottom: '20px', opacity: '1' }}>
                <div className="row gx-4 justify-content-center">
                    <div className="col-lg-8">
                        <h2 style={{ color: 'black' }}>PAGOS</h2>
                        <p className="lead" style={{ textAlign: 'justify', color: 'black', fontWeight: 'bold' }}>TRANSFERENCIAS (por WhatsApp) a Cuenta Rut, Mercadopago y Paypal.</p>
                        <ul>
                            <li>Aceptamos la mayoría de los medios de pago.</li>
                            <li>Se le enviará una boleta electrónica de su compra.</li>
                            <li>Los valores incluyen impuesto.</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="container px-4" style={{ marginBottom: '20px', opacity: '1' }}>
                <div className="row gx-4 justify-content-center">
                    <div className="col-lg-8">
                        <h2 style={{ color: 'black' }}>DEVOLUCIONES</h2>
                        <p className="lead" style={{ textAlign: 'justify', color: 'black' }}>En el caso que el cliente se arrepienta de una compra y tenga en su poder el o los productos, los gastos de despacho por motivo de devolución corren por cuenta del cliente, y se deberá enviar a domicilio. Las devoluciones de dinero se realizarán una vez recibido el o los productos en las mismas condiciones descritas. Además, si paga a crédito los plazos de devolución del dinero dependerá de la disponibilidad del monto total a devolver.</p>
                        <ul>
                            <li>En el caso que no se haya realizado el despacho, el plazo de devolución del dinero dependerá de las entidades bancarias cuando el pago sea con Tarjeta Crédito/Débito/Paypal.</li>
                            <li>Las transferencias se devolverán en un plazo de 12 horas una vez acreditado el pago.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section></div>);
}

export default Header;