
import { useEffect, useState } from 'react';
import '../css/style.css';
import '../App.css';
import data from "../helps/output.json";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';
/* import Swal from 'sweetalert2' */
import {agregarAlCarrito } from './carrito.js';
import {Cart4, InfoLg} from 'react-bootstrap-icons';
import './carrito.js';
function App(props) {
  /*     let productos = []; */
  const [productos, setProductos] = useState([]);
  const pedirproducto = () => {
    return new Promise((resolve, reject) => {
      resolve(data);
    })
  }
  useEffect(() => {
    pedirproducto()
      .then((res) => {
        /* productos = res; */
        setProductos(res);
      });

  }, [])


  return (<div className="container-fluid pt-5 pb-3" >
    <h2 className="section-title position-relative text-uppercase mx-xl-5 mb-4" key={`${props.categoriaId}`}><span className="bg-secondary pr-3">{props.categoriaNombre}</span></h2>
    <div className="row px-xl-5">
    {props.categoriaId === String(0) &&
        productos.length > 0 &&
        productos.filter((producto, i) => producto.vendido === 'NO' && producto.cantidad > 0).map((producto, i) => {
          const nf = new Intl.NumberFormat("es-CL");
          var valorProductoArt = `$ ${nf.format(producto.valor)}`;
          var fondo_item = "bg-light";
          var estadoV = "visible";
          var oferta = 'none';
          if(producto.valor < producto.valor_anterior)
            oferta = 'visible';
          if (producto.vendido !== "NO") {
            valorProductoArt = "VENDIDO";
            fondo_item = "bg-light-vendido";
            estadoV = "hidden";
          }

          return (

            <div className="col-lg-3 col-md-4 col-sm-6 pb-1"  key={i}>
              <div className={`product-item ${fondo_item} mb-4`}>
                <div className="product-LazyLoadImage position-relative overflow-hidden">
                  <LazyLoadImage className="LazyLoadImage-fluid w-100" src={`${producto.foto1}_i.JPG`} alt="" />
                  <div className="product-action">
                    <button className="btn btn-outline-primary producto-agregar" style={{ visibility: estadoV }} id={`${producto.id}`} onClick={agregarAlCarrito}><Cart4 size={24} color='white'></Cart4></button>
                  </div>
                </div>
                <div className="text-center py-4">
                  <Link className="h6 text-decoration-none text-truncate" style={{ color: 'black, !important' }}>{producto.descripcion}</Link>
                  <div className="d-flex align-items-center justify-content-center mt-2">
                    <h5>{valorProductoArt}</h5><h5 className="text-muted ml-2"><del style={{ color: 'brown', display: oferta, fontWeight: 'lighter'}}>${nf.format(producto.valor_anterior)}</del></h5>
                  </div>
                  <div className="d-flex align-items-center justify-content-center mb-1">
                    <Link className="btn btn-outline-primary" to={`/products/${producto.url}`}><InfoLg size={24} color='blue'></InfoLg></Link>
                 {/*  </div>
                  <div className="d-flex align-items-center justify-content-center mb-1"> */}
                   &nbsp;<button className="btn btn-outline-primary producto-agregar" style={{ visibility: estadoV }} id={`${producto.id}`} onClick={agregarAlCarrito}><Cart4 size={24} color='blue'></Cart4></button>
                  </div>
                </div>
              </div>
            </div>
          )
        })
      }
      {props.categoriaId === String(13) &&
        productos.length > 0 &&
        productos.filter((producto,i) => producto.destacado === 'SI').map((producto, i) => {
          const nf = new Intl.NumberFormat("es-CL");
          const foto1 = '../assets/productos/enbreve'
          if (producto.foto1 === "") { producto.foto1 = foto1; }
          var valorProductoArt = `$ ${nf.format(producto.valor)}`;
          var fondo_item = "bg-light";
          var estadoV = "visible";
          var oferta = 'none';
          if(producto.valor < producto.valor_anterior)
            oferta = 'visible';
          if (producto.vendido !== "NO") {
            valorProductoArt = "VENDIDO";
            fondo_item = "bg-light-vendido";
            estadoV = "hidden";
          }

          return (

            <div className="col-lg-3 col-md-4 col-sm-6 pb-1"  key={i}>
              <div className={`product-item ${fondo_item} mb-4`}>
                <div className="product-LazyLoadImage position-relative overflow-hidden">
                  <LazyLoadImage className="LazyLoadImage-fluid w-100" src={`${producto.foto1}_i.JPG`} alt="" />
                  <div className="product-action">
                    <button className="btn btn-outline-primary producto-agregar" style={{ visibility: estadoV }} id={`${producto.id}`} onClick={agregarAlCarrito}><Cart4 size={24} color='white'></Cart4></button>
                  </div>
                </div>
                <div className="text-center py-4">
                  <Link className="h6 text-decoration-none text-truncate" style={{ color: 'black, !important' }}>{producto.descripcion}</Link>
                  <div className="d-flex align-items-center justify-content-center mt-2">
                    <h5>{valorProductoArt}</h5><h5 className="text-muted ml-2"><del style={{ color: 'brown', display: oferta, fontWeight: 'lighter' }}>${nf.format(producto.valor_anterior)}</del></h5>
                  </div>
                  <div className="d-flex align-items-center justify-content-center mb-1">
                    <Link className="btn btn-outline-primary" to={`/products/${producto.url}`}><InfoLg size={24} color='blue'></InfoLg></Link>
                 {/*  </div>
                  <div className="d-flex align-items-center justify-content-center mb-1"> */}
                   &nbsp;<button className="btn btn-outline-primary producto-agregar" style={{ visibility: estadoV }} id={`${producto.id}`} onClick={agregarAlCarrito}><Cart4 size={24} color='blue'></Cart4></button>
                  </div>
                </div>
              </div>
            </div>
          )
        })
      }
      {props.categoriaId === String(14) &&
        productos.length > 0 &&
        productos.filter((producto,i) => producto.novedad === 'SI').map((producto, i) => {
          const nf = new Intl.NumberFormat("es-CL");
          const foto1 = '../assets/productos/enbreve'
          if (producto.foto1 === "") { producto.foto1 = foto1; }
          var valorProductoArt = `$ ${nf.format(producto.valor)}`;
          var fondo_item = "bg-light";
          var estadoV = "visible";
          var oferta = 'none';
          if(producto.valor < producto.valor_anterior)
            oferta = 'visible';
          if (producto.vendido !== "NO") {
            valorProductoArt = "VENDIDO";
            fondo_item = "bg-light-vendido";
            estadoV = "hidden";
          }

          return (

            <div className="col-lg-3 col-md-4 col-sm-6 pb-1" key={i}>
              <div className={`product-item ${fondo_item} mb-4`}>
                <div className="product-LazyLoadImage position-relative overflow-hidden">
                  <LazyLoadImage className="LazyLoadImage-fluid w-100" src={`${producto.foto1}_i.JPG`} alt="" />
                  <div className="product-action">
                    <button className="btn btn-outline-primary producto-agregar" style={{ visibility: estadoV }} id={`${producto.id}`} onClick={agregarAlCarrito}><Cart4 size={24} color='white'></Cart4></button>
                  </div>
                </div>
                <div className="text-center py-4">
                  <Link className="h6 text-decoration-none text-truncate" style={{ color: 'black, !important' }}>{producto.descripcion}</Link>
                  <div className="d-flex align-items-center justify-content-center mt-2">
                    <h5>{valorProductoArt}</h5><h5 className="text-muted ml-2"><del style={{ color: 'brown', display: oferta, fontWeight: 'lighter' }}>${nf.format(producto.valor_anterior)}</del></h5>
                  </div>
                  <div className="d-flex align-items-center justify-content-center mb-1">
                    <Link className="btn btn-outline-primary" to={`/products/${producto.url}`}><InfoLg size={24} color='blue'></InfoLg></Link>
                 {/*  </div>
                  <div className="d-flex align-items-center justify-content-center mb-1"> */}
                   &nbsp;<button className="btn btn-outline-primary producto-agregar" style={{ visibility: estadoV }} id={`${producto.id}`} onClick={agregarAlCarrito}><Cart4 size={24} color='blue'></Cart4></button>
                  </div>
                </div>
              </div>
            </div>
          )
        })
      }
      {(props.categoriaId !== String(13)) && (props.categoriaId !== String(14) && props.categoriaId !== String(0)) &&
        productos.length > 0 &&
        productos.filter((producto, i) => producto.categorias === Number(props.categoriaId)).map((producto, i) => {
          const nf = new Intl.NumberFormat("es-CL");
          const foto1 = '../assets/productos/enbreve'
          if (producto.foto1 === "") { producto.foto1 = foto1; }
          var valorProductoArt = `$ ${nf.format(producto.valor)}`;
          var fondo_item = "bg-light";
          var estadoV = "visible";
          var oferta = 'none';
          if(producto.valor < producto.valor_anterior)
            oferta = 'visible';
          if (producto.vendido !== "NO") {
            valorProductoArt = "VENDIDO";
            fondo_item = "bg-light-vendido";
            estadoV = "hidden";
          }

          return (

            <div className="col-lg-3 col-md-4 col-sm-6 pb-1" key={i}>
              <div className={`product-item ${fondo_item} mb-4`}>
                <div className="product-LazyLoadImage position-relative overflow-hidden">
                  <LazyLoadImage className="LazyLoadImage-fluid w-100" src={`${producto.foto1}_i.JPG`} alt="" />
                  <div className="product-action">
                    <button className="btn btn-outline-light producto-agregar" style={{ visibility: estadoV }} id={`${producto.id}`} onClick={agregarAlCarrito}><Cart4 size={24} color='white'></Cart4></button>
                  </div>
                </div>
                <div className="text-center py-4">
                  <Link className="h6 text-decoration-none text-truncate" style={{ color: 'black, !important' }}>{producto.descripcion}</Link>
                  <div className="d-flex align-items-center justify-content-center mt-2">
                    <h5>{valorProductoArt}</h5><h5 className="text-muted ml-2"><del style={{ color: 'brown', display: oferta, fontWeight: 'lighter' }}>${nf.format(producto.valor_anterior)}</del></h5>
                  </div>
                   <div className="d-flex align-items-center justify-content-center mb-1">
                    <Link className="btn btn-outline-primary" to={`/products/${producto.url}`}><InfoLg size={24} color='blue'></InfoLg></Link>
                 {/*  </div>
                  <div className="d-flex align-items-center justify-content-center mb-1"> */}
                   &nbsp;<button className="btn btn-outline-primary producto-agregar" style={{ visibility: estadoV }} id={`${producto.id}`} onClick={agregarAlCarrito}><Cart4 size={24} color='blue'></Cart4></button>
                  </div>
                </div>
              </div>
            </div>
          )
        })
      }</div>
  </div>)
}

export default App;
