
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Portada from './pages/Portada.js';
import Registro from './pages/Registro.js';
import PagosDespachos from './pages/PagosDespachos.js';
import Login from './pages/Login.js';
import Contacto from './pages/Contacto.js';
import Cart from './pages/Cart.js';
import Search from './pages/Search.js';
import All from './pages/All.js';
import DetalleProducto from './pages/DetalleProducto.js';
import Productos from './pages/Productos.js';
import NavBar from './components/Navbar.js';
import TopStart from './components/topStart.js';

function App() {
  return (
   <div>
    <BrowserRouter>
    <TopStart/>
    <NavBar/>
      <Routes>
        <Route path="/" element={<Portada />} />
        <Route path="/account/register" element={<Registro />} />
        <Route path="/pages/pagos-y-despachos" element={<PagosDespachos />} />
        <Route path="/account/login" element={<Login />} />
        <Route path="/pages/contact" element={<Contacto />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/search" element={<Search />} />
        <Route path="/collections/all" element={<All />} />
        <Route path="/collections/figuras-de-accion" element={<Productos categoriaId="1" categoriaNombre="Acción" />} />
        <Route path="/collections/figuras-articuladas" element={<Productos categoriaId="2" categoriaNombre="Articuladas" />} />
        <Route path="/collections/autitos" element={<Productos categoriaId="3" categoriaNombre="Autitos" />} />
        <Route path="/collections/figuras-monocromaticas" element={<Productos categoriaId="4" categoriaNombre="Monocromáticas" />} />
        <Route path="/collections/papeleria" element={<Productos categoriaId="5" categoriaNombre="Papelería" />} />
        <Route path="/collections/peliculas" element={<Productos categoriaId="6" categoriaNombre="Películas" />} />
        <Route path="/collections/figuras-plasticas" element={<Productos categoriaId="7" categoriaNombre="Plásticas" />} />
        <Route path="/collections/promocionales" element={<Productos categoriaId="8" categoriaNombre="Promocionales" />} />
        <Route path="/collections/figuras-de-pvc" element={<Productos categoriaId="9" categoriaNombre="PVC" />} />
        <Route path="/collections/rompecabezas" element={<Productos categoriaId="10" categoriaNombre="Rompecabezas" />} />
        <Route path="/collections/videojuegos" element={<Productos categoriaId="11" categoriaNombre="Videojuegos" />} />
        <Route path="/collections/variados" element={<Productos categoriaId="12" categoriaNombre="Variados" />} />
        <Route path="/collections/destacados" element={<Productos categoriaId="13" categoriaNombre="Productos Destacados" />} />
        <Route path="/collections/news" element={<Productos categoriaId="14" categoriaNombre="Productos Recientes" />} />
        <Route path="/products/:url" element={<DetalleProducto />} />
        <Route path="*" element={<Navigate to="/"/>} />
      </Routes>
    </BrowserRouter></div>
  );
}

export default App;