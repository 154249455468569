import { useEffect, useState } from 'react';
import '../css/style.css';
import '../App.css';
/* import data from "../helps/output.json";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom'; */
import Swal from 'sweetalert2'
import './carrito.js';
import { /* actualizarBotonesElininar, */  eliminarDelCarrito} from './carrito.js';
import {XLg} from 'react-bootstrap-icons';

function App() {

  const [productosEnCarrito, setProductosEnCarrito] = useState(JSON.parse(localStorage.getItem("rounded-circle")) || []);
const handleClick = (e) => {

    eliminarDelCarrito(e);
    setProductosEnCarrito(JSON.parse(localStorage.getItem("rounded-circle")));
  };

  const clickingPago2 = () => {
    Swal.fire({
        title: `¿Realizar Pago por $ ${nf.format(TvalorProductoArt)}.-?`,
        text: "Es un link externo de mercadopago",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ir!"
    }).then((result) => {
        if (result.isConfirmed) {
            const url = 'https://link.mercadopago.cl/toytek';
            var win = window.open(url, '_blank');
            win.focus();
        }
    });
}

const cargarProductos = () => {
    const productos = JSON.parse(localStorage.getItem("rounded-circle")) || [];
    setProductosEnCarrito(productos);
};

useEffect(() => {
    cargarProductos();
 }, []); // Solo al montar

  
  let TvalorProductoArt = 0, TvalorProductoArtFormat = 0;
  const nf = new Intl.NumberFormat("es-CL");
    

  return (<div className="container-fluid">
<div className="row px-xl-5">
    <div className="col-lg-8 table-responsive mb-5">
        <table className="table table-light table-borderless table-hover text-center mb-0">
            <thead className="thead-dark">
                <tr>
                    <th>Productos</th>
                    <th>Precio</th>
                    <th>Eliminar</th>
                </tr>
            </thead>
            <tbody className="align-middle">
            {   productosEnCarrito.length > 0 &&
                productosEnCarrito.map((producto, i) => {
          const nf = new Intl.NumberFormat("es-CL");
          var valorProductoArt = `$ ${nf.format(producto.valor)}`;
          TvalorProductoArt = productosEnCarrito.reduce((acc, producto) => acc + producto.valor, 0);
          TvalorProductoArtFormat = `$ ${nf.format(TvalorProductoArt)}`;
            
          return (

            <tr  key={`${producto.id}`} >
                    <td className="align-middle" style={{textAlign: 'left'}}>{i+1} <img src={`${producto.foto1}_i.JPG`} alt="" style={{width: '50px'}} />&nbsp;&nbsp;&nbsp;{producto.descripcion}</td>
                    <td className="align-middle">{valorProductoArt}</td>
                    <td className="align-middle"><button className="btn btn-sm btn-danger carrito-producto-eliminar" id={`${producto.id}`} onClick={handleClick}><XLg size={18} color='white'></XLg></button></td>
                </tr>
          )

          
        })
        
      }
                
                
            </tbody>
        </table>
    </div>
    <div className="col-lg-4">
        <form className="mb-30" action="">
            <div className="input-group">
                <input type="text" className="form-control border-0 p-4" placeholder="Código" />
                <div className="input-group-append">
                    <button className="btn btn-primary" disabled>Aplicar Código</button>
                </div>
            </div>
        </form>
        <h5 className="section-title position-relative text-uppercase mb-3"><span className="bg-secondary pr-3" style={{color:'white'}}>Resumen del Carrito</span></h5>
        <div className="bg-light p-30 mb-5">
            <div className="border-bottom pb-2">
                <div className="d-flex justify-content-between mb-3">
                    <h6>Sub Total</h6>
                    <h6>{TvalorProductoArtFormat}</h6>
                </div>
                <div className="d-flex justify-content-between">
                    <h6 className="font-weight-medium">Despacho <span style={{color:'blue',fontWeight: 'lighter'}}>STARKEN</span><span style={{color:'blue',fontWeight: 'bold'}}> VALPARAÍSO</span></h6>
                    <h6 className="font-weight-medium">por PAGAR</h6>
                </div>
            </div>
            <div className="pt-2">
                <div className="d-flex justify-content-between mt-2">
                    <h5>Total</h5>
                    <h5>{TvalorProductoArtFormat}</h5>
                </div>
                <h6 style={{textAlign: 'right'}}>Impuesto incluido</h6>
                <button className="btn btn-block btn-primary font-weight-bold my-3 py-3" disabled={productosEnCarrito.length === 0} onClick={clickingPago2}>Realizar el Pago</button>
            </div>
        </div>
    </div>
</div>
</div>);
}

export default App;