import '../css/style.css';
import '../App.css';
import Video from '../components/video.js';
import Wsp from '../components/wsp.js';
import Categorias from '../components/categorias.js';

import PDetalle from '../components/productoDetalle.js';
function App() {

return (
  <>
  <Video  nombre="../assets/video/toytek.mp4" 
          caption="portada" 
          replay={true} 
    /><PDetalle />
            <div><Categorias /></div>
            <Wsp /></>
);
}

export default App;