import React from 'react'
import '../css/Video.css';
/* import * as Icon from 'react-bootstrap-icons';
import CardPortada from './CardPortada.js';
 */
/* import Table from './Table.js';
import Form from './Formulario_contacto.js'; */
function Video(props) {
 return (
<div className={props.caption}>
  <video id='video1' autoPlay loop={props.replay} muted>
    <source src={props.nombre} type = 'video/mp4' />
  </video>  


  {props.string}
{/*     <h1><Icon.VolumeMuteFill size={20} title=' ' ></Icon.VolumeMuteFill></h1> */}

</div>
 );
}


export default Video;