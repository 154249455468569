
/* import { useEffect, useState } from 'react'; */
import { Link } from 'react-router-dom';
import '../css/style.css';
import {Cart4} from 'react-bootstrap-icons';
import { /* actualizarBotonesAgregar, agregarAlCarrito,  */actualizarNumeroce } from './carrito.js';

function CarroNum() {
/*     const [productosEnCarrito, setProductosEnCarrito] = useState(JSON.parse(localStorage.getItem("rounded-circle")) || []);
    const [cantidadCarrito, setCantidadCarrito] = useState(0);
  
     // Función para cargar productos del carrito desde localStorage
  const cargarProductos = () => {
    try {
      const productos = JSON.parse(localStorage.getItem("rounded-circle")) || [];
      setProductosEnCarrito(productos);
    } catch (error) {
      console.error('Error al cargar productos del carrito:', error);
      setProductosEnCarrito([]);
    }
  };
      // Escuchar cambios en el carrito cada vez que productosEnCarrito cambie
  useEffect(() => {
    // Actualiza la cantidad total en el carrito cuando cambia el array de productos
    const cantidad = productosEnCarrito.reduce((acc, producto) => acc + producto.cantidad, 0);
    setCantidadCarrito(cantidad);

  }, [productosEnCarrito]); // Dependencia en productosEnCarrito

  useEffect(() => {
      cargarProductos();

}, [cantidadCarrito]); */


/* 
    function actualizarNumeroce() {
        const productosEnCarrito = JSON.parse(localStorage.getItem("rounded-circle")) || [];
        let numeroc = productosEnCarrito.reduce((acc, producto) => acc + producto.cantidad, 0);
        return Number(numeroc) == null ? 0 : Number(numeroc);
    } */

    return (<Link to="/cart" className="nav-item nav-link">
        <Cart4 size={24} color='white'></Cart4>&nbsp;
        <span id="numerito" className="badge text-secondary border border-secondary rounded-circle" style={{ paddingBottom: '2px' }}>{actualizarNumeroce()}</span>
    </Link>);
}


export default CarroNum;

