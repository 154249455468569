import { LazyLoadImage } from 'react-lazy-load-image-component';
import {PinMap, Envelope, PhoneVibrate} from 'react-bootstrap-icons';

function App() {
    
  return (<div className="container-fluid">
        <h2 className="section-title position-relative text-uppercase mx-xl-5 mb-4"><span className="bg-secondary pr-3">Contactenos</span></h2>
        <div className="row px-xl-5">
            <div className="col-lg-7 mb-5">
                <div className="contact-form bg-light p-30">
{/*                     <div id="success"></div> */}
                    <form name="sentMessage" key="contactForm">
                        <div className="control-group">
                            <input type="text" className="form-control" key="name" placeholder="Su Nombre"
                                required="required" data-validation-required-message="Su nombre" />
                            <p className="help-block text-danger"></p>
                        </div>
                        <div className="control-group">
                            <input type="email" className="form-control" key="email" placeholder="Su Email"
                                required="required" data-validation-required-message="Su email" />
                            <p className="help-block text-danger"></p>
                        </div>
                        <div className="control-group">
                            <input type="text" className="form-control" key="subject" placeholder="Su Motivo"
                                required="required" data-validation-required-message="Su motivo" />
                            <p className="help-block text-danger"></p>
                        </div>
                        <div className="control-group">
                            <textarea className="form-control" rows="8" key="message" placeholder="Su Mensaje"
                                required="required"
                                data-validation-required-message="Su Mensaje"></textarea>
                            <p className="help-block text-danger"></p>
                        </div>
                        <div>
                            <button className="btn btn-primary py-2 px-4" type="submit" id="sendMessageButton">Enviar Mensaje</button>
                        </div>
                    </form>
                </div>
            </div>
            <div className="col-lg-5 mb-5">
                <div className="bg-light p-30 mb-30">
                {/*     <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3001156.4288297426!2d-78.01371936852176!3d42.72876761954724!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4ccc4bf0f123a5a9%3A0xddcfc6c1de189567!2sNew%20York%2C%20USA!5e0!3m2!1sen!2sbd!4v1603794290143!5m2!1sen!2sbd"
                    frameborder="0" style={{border: '0', width: '100%', height: '233px'}} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe> */}
                 <LazyLoadImage style={{border: '0', height: '240px'}} className="img-fluid" src={`../assets/carousel/carousel-1.jpg`}  alt="" key="carr-1"/>
                 </div>
                <div className="bg-light p-30 mb-3">
                    <p className="mb-2"><PinMap size={24} color='blue'></PinMap> Valparaíso - Chile</p>
                    <p className="mb-2"><Envelope size={24} color='blue'></Envelope> hola@toytek.cl</p>
                    <p className="mb-2"><PhoneVibrate size={24} color='blue'></PhoneVibrate> +56 9 9930 2909</p>
                </div>
            </div>
        </div>
    </div>);
}

export default App;
