/* import * as Icon from 'react-bootstrap-icons'; */
import Video from '../components/video.js';
import Wsp from '../components/wsp.js';
import Elementos from '../components/Productos.js';
/* import Afiches from '../components/afiches.js'; */
function App(props) {

    return (
        <>
            <Video nombre="../assets/video/toytek.mp4"
                caption="portada"
                replay={true}
            />{/* <div>
                <Afiches numero1='1'
                    titulo1=''
                    texto1=''
                    carpeta1='productos'
                    boton1={<Icon.VolumeUpFill size={25}></Icon.VolumeUpFill>}
                    visibleboton1='hidden'
                    numero2='2'
                    titulo2=''
                    texto2=''
                    carpeta2='productos'
                    boton2={<Icon.VolumeUpFill size={25}></Icon.VolumeUpFill>}
                    visibleboton2='hidden'></Afiches></div> */}
            {(props.categoriaId === '13') && (<Elementos categoriaId={props.categoriaId} categoriaNombre={props.categoriaNombre}/>)}
            {(props.categoriaId === '14') && (<Elementos categoriaId={props.categoriaId} categoriaNombre={props.categoriaNombre}/>)}
            {(props.categoriaId !== '13') && (props.categoriaId !== '14') && (<Elementos categoriaId={props.categoriaId} categoriaNombre={props.categoriaNombre}/>)}
            <Wsp /></>
    );
}

export default App;