import Video from '../components/video.js';
import Wsp from '../components/wsp.js';
import Search from '../components/Searcher.js';
/* import { useEffect } from 'react'; */
function App() {

/*  const leeurl = () => {
  const href = window.location.href; */
/*   console.log(window.location.search.substring(1,window.location.search.length)); */
 
/*  }  */

/*  useEffect(() => {
  leeurl();
}, []); */ // Solo al montar

return (
  <>
  <Video  nombre="../assets/video/toytek.mp4" 
          caption="portada" 
          replay={true} 
   /><Search valor={window.location.search.substring(1,window.location.search.length)} categoriaNombre={'Busqueda'}/>
    <Wsp></Wsp></>
);
}

export default App;