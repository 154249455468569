
import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link, useParams } from 'react-router-dom';
import data from "../helps/output.json";
import Breadcrumb from '../components/breadcrumb.js';
import '../css/style.css';
import '../App.css';
import{ArrowLeftShort, ArrowRightShort, Cart4} from 'react-bootstrap-icons';
import { /* actualizarBotonesAgregar, agregarAlCarrito,  */agregarAlCarritoDetalle } from './carrito.js';

function App() {
    const [itemValor, setValor] = useState('VENDIDO');
    const [item, setItem] = useState([]);
    const url = useParams().url;

    const cambio = (url) => {
        return new Promise((resolve, reject) => {
            const item = data.find((el) => el.url === url);
            const nf = new Intl.NumberFormat("es-CL");
            if (item) {
                if (item.cantidad > 0)
                    resolve(`$ ${nf.format(item.valor)}.-`);
                else
                    resolve(`VENDIDO`);
            }
        })
    }

    const pedirDatoporURL = (url) => {
        return new Promise((resolve, reject) => {
            const item = data.find((el) => el.url === url);
            if (item) {
                resolve(item);
            } else {
                /* reject({ error: "No se encontro el producto" }); */
                const url = 'https://www.toytek.cl';
                var win = window.open(url, '_parent');
                win.focus();

            }


        })
    }

    useEffect(() => {
        pedirDatoporURL(url)
            .then((res) => {
                setItem(res);
            })
    }, [url])

    useEffect(() => {
        cambio(url).then((res) => {
            setValor(res);
        })

    }, [url]);


    const textAlt = item.descripcion;
    const newTextAlt = textAlt;

/*     const foto1 = '../assets/productos/enbreve_i'
    if (item.foto1 === "") { item.foto1 = foto1; }
    const foto2 = '../assets/productos/enbreve_i'
    if (item.foto2 === "") { item.foto2 = foto2; } */
/*     const nf = new Intl.NumberFormat("es-CL"); */



    /*       const RevisaImagen = (props) => {
            if(props.src !== null && props.src !== ''){
                return  (<div className="carousel-item"><LazyLoadImage className="w-100 h-100" src={`${props.src}.JPG`} alt={props.alt} /></div>)
            }
           
        }
     */
    return (<><Breadcrumb categoria={item.cat_nombre} descripcion={item.descripcion} url={item.cat_url}></Breadcrumb><div className="container-fluid pb-5">
        <div className="row px-xl-5">
            <div className="col-lg-5 mb-30">
                <div id="product-carousel" className="carousel slide" data-ride="carousel">
                    <div className="carousel-inner bg-light">
                        <div className="carousel-item active">
                            <LazyLoadImage className="w-100 h-100" src={`${item.foto1}.JPG`} alt={`${newTextAlt}-1`} />
                        </div>
                        <div className="carousel-item">
                            <LazyLoadImage className="w-100 h-100" src={`${item.foto2}.JPG`} alt={`${newTextAlt}-2`} />
                        </div>
                    </div>
                    <Link className="carousel-control-prev" data-target="#product-carousel" data-slide="prev">
                    <ArrowLeftShort size={48} color='white'></ArrowLeftShort>
                    </Link>
                    <Link className="carousel-control-next" data-target="#product-carousel" data-slide="next">
                    <ArrowRightShort size={48} color='white'></ArrowRightShort>
                    </Link>
                </div>
            </div>

            <div className="col-lg-7 h-auto mb-30">
                <div className="h-100 bg-light p-30">
                    <h3>{item.descripcion}</h3>
                    <div className="d-flex mb-3">
                        <div className="text-primary mr-2">

                        </div>

                    </div>
                    <h3 className="font-weight-semi-bold mb-4">{item.cat_nombre}</h3>
                    <p className="mb-4">{item.descripcion}</p>
                    <div className="d-flex mb-3">

                    </div>
                    <div className="d-flex mb-4">
                        {itemValor}
                    </div>
                    <div className="d-flex align-items-center mb-4 pt-2" >
                        <div className="input-group quantity mr-3" style={{ width: '130px' }}>
                            <div className="input-group-btn">
                                <button className="btn btn-primary btn-minus" disabled>
                                <ArrowLeftShort size={20} color='white'></ArrowLeftShort>
                                </button>
                            </div>
                            <input type="text" className="form-control bg-secondary border-0 text-center" value={item.cantidad} />
                            <div className="input-group-btn">
                                <button className="btn btn-primary btn-plus" disabled>
                                <ArrowRightShort size={20} color='white'></ArrowRightShort>
                                </button>
                            </div>
                        </div>
                        <button className="btn btn-primary px-3 producto-agregar" style={{ visibility: itemValor === 'VENDIDO' ? 'hidden' : 'visible', color: 'white, !important' }} id={`${item.id}`} onClick={agregarAlCarritoDetalle}><Cart4 size={24} color='white'></Cart4></button>

                    </div>
                </div>
            </div>
        </div>
        <div className="row px-xl-5">
            <div className="col">
                <div className="bg-light p-30">
                    <div className="nav nav-tabs mb-4">
                        <Link className="nav-item nav-link text-dark2 active" data-toggle="tab" to="">Descripción</Link>
                    </div>
                    <div className="tab-content">
                        <div className="tab-pane fade show active" id="tab-pane-1">
                            <h4 className="mb-3">Detalle</h4>
                            <p>{item.descripcion}</p>
                            <p><li>{item.estado}.</li></p>
                            <p><li>Todos los artículos son recomendados <b>para mayores de 3 años</b>.</li></p>
                            <p><li>Los despachos se realizan desde: <b>{item.sucursal} - Chile</b>.</li></p>
                            <p><li>Los despachos son por <b>{item.empresa_envio} {item.envio}</b>.</li></p>
                            <p><li>Si tiene dudas consulte.</li></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div></>)
}

export default App;
