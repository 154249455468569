 
import { /* useEffect, */ useState} from 'react';
/*import { actualizarNumeroce} from './carrito.js'; */
import CarroNumero from './carroNumero.js'
import { Link } from 'react-router-dom';
import * as Icon from 'react-bootstrap-icons';

function App() {

    const [Search, setSearch] = useState("");


    function search1() {
        const search1 = document.getElementById('1s').value;
        if (search1.trim() !== '') {
        const url = window.location.protocol+'//'+window.location.host+'/Search?'+search1;
        var win = window.open(url, '_parent');
        win.focus();
        }
    }

    function search2(){
        const search2 = document.getElementById('2s').value;
        if (search2.trim() !== '') {
        const url =  window.location.protocol+'//'+window.location.host+'/Search?'+search2;
        var win = window.open(url, '_parent');
        win.focus();
        }
    } 

    const searcher = (e) => {
  /*       const inputValue = e.target.value; */
/*         if (inputValue.trim() !== '') { */
        setSearch(e.target.value);
      /*   } */
/*         console.log(Search); */
    }
/*     useEffect(() => {

     }, []);
 */
    return (<div className="container-fluid" style={{ position: 'relative', zIndex: '999' }}>
    
        <div className="row bg-secondary py-1 px-xl-5" style={{textAlign: 'center'}}>
            <div className="col-lg-6 d-lg-none">
                <CarroNumero />
            </div>
        </div>
        <div className="row bg-secondary py-1 px-xl-5">
            <div className="col-lg-6 d-lg-none">
                <div className="align-items-center h-100 ">
                    <form action="">
                        <div className="input-group">
                            <input id="1s" type="text" className="form-control" placeholder="Buscar productos" onChange={searcher} value={Search}/>
                            <div className="input-group-append">
                                <span className="input-group-text text-primary">
                                    <Icon.Search size={24} onClick={search1}></Icon.Search>
                                </span>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div className="row align-items-center bg-light py-3 px-xl-5 d-none d-lg-flex">
            <div className="col-lg-4">
                <Link href="" className="text-decoration-none">
                    <img id="logo-img" className="img-fluid" src={require(`../assets/logo/toytek.png`)} alt="" style={{ width: '150px' }} />
                </Link>
            </div>
            <div className="col-lg-4 col-6 text-left">
                <form action="">
                    <div className="input-group">
                        <input id="2s" type="text" className="form-control" placeholder="Buscar productos" onChange={searcher} value={Search}/>
                        <div className="input-group-append">
                            <span className="input-group-text bg-transparent text-primary">
                            <Icon.Search size={24} onClick={search2}></Icon.Search>
                            </span>
                        </div>
                    </div>
                </form>
            </div>
            <div className="col-lg-4 col-6 text-right">

            </div>
        </div>
    </div>);
}

export default App;