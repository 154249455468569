import React from 'react'
import { FloatingWhatsApp } from 'react-floating-whatsapp'

export default function App() {

  return (
    <FloatingWhatsApp style={{color:'black'}} phoneNumber="+56 9 9930 2909" 
                        accountName="TOYTEK.CL" 
                        chatMessage="Hola, en que puedo ayudarte?"
                        placeholder="Escriba aquí su consulta..."
                        statusMessage="hola@toytek.cl"
                        avatar="/assets/logo/T.jpg"
                        notificationDelay="3"
                        messageDelay="3"
                        allowClickAway={true}
                        notificationSound={true}/>
  );
}