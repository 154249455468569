
import { useEffect, useState } from 'react';
import '../css/style.css';
import '../App.css';
import data from "../helps/output.json";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';
/* import Swal from 'sweetalert2' */
import { agregarAlCarrito } from './carrito.js';
import './carrito.js';
import * as Icon from 'react-bootstrap-icons';


function App(props) {
  /*     let productos = []; */
  const [productos, setProductos] = useState([]);
  const pedirproducto = () => {
    return new Promise((resolve, reject) => {
      resolve(data);
    })
  }
  useEffect(() => {
    pedirproducto()
      .then((res) => {
        /* productos = res; */
        setProductos(res);
      });

/*       if (!sessionStorage.getItem('reloadedP')) {
        sessionStorage.setItem('reloadedP', 'true'); // Marca que se ha recargado
        window.location.reload(true);               // Recarga la página
      } */
  }, [])

  let valorReemplazado = props.valor.replace('%20', ' ').toLowerCase(); // Reemplaza espacios por guiones (ejemplo)
if(valorReemplazado === 'et'){valorReemplazado = 'e.t. el extraterrestre'}
if(valorReemplazado === 'heman' || valorReemplazado === 'motu'){valorReemplazado = 'masters'}
  return (<div className="container-fluid pt-5 pb-3">
    <h2 className="section-title position-relative text-uppercase mx-xl-5 mb-4"><span className="bg-secondary pr-3">{props.categoriaNombre}</span></h2>
    <div className="row px-xl-5">
   
      {productos.length > 0 &&
        productos.filter((producto) =>
  ['descripcion', 'cat_nombre'].some((key) =>
    producto[key].toLowerCase().includes(valorReemplazado.toLowerCase())
  )
).map((producto, i) => {
          const nf = new Intl.NumberFormat("es-CL");
          const foto1 = '../assets/productos/enbreve'
          if (producto.foto1 === "") { producto.foto1 = foto1; }
          var valorProductoArt = `$ ${nf.format(producto.valor)}`;
          var fondo_item = "bg-light";
          var estadoV = "visible";
          if (producto.vendido !== "NO") {
            valorProductoArt = "VENDIDO";
            fondo_item = "bg-light-vendido";
            estadoV = "hidden";
          }

          return (

            <div key={`${producto.id}`} className="col-lg-3 col-md-4 col-sm-6 pb-1">
              <div className={`product-item ${fondo_item} mb-4`}>
                <div className="product-LazyLoadImage position-relative overflow-hidden">
                  <LazyLoadImage className="LazyLoadImage-fluid w-100" src={`${producto.foto1}_i.JPG`} alt="" />
                  <div className="product-action">
                    <button className="btn btn-outline-primary producto-agregar" style={{ visibility: estadoV }} id={`${producto.id}`} onClick={agregarAlCarrito}><Icon.Cart4 size={24} color='blue'></Icon.Cart4></button>
                  </div>
                </div>
                <div className="text-center py-4">
                  <Link className="h6 text-decoration-none text-truncate" style={{ color: 'black, !important' }}>{producto.descripcion}</Link>
                  <div className="d-flex align-items-center justify-content-center mt-2">
                    <h5>{valorProductoArt}</h5><h6 className="text-muted ml-2"><del style={{ color: 'brown', display: 'none' }}>${nf.format(producto.valor_anterior)}</del></h6>
                  </div>
                   <div className="d-flex align-items-center justify-content-center mb-1">
                    <Link className="btn btn-outline-primary" to={`/products/${producto.url}`}><Icon.InfoLg size={24} color='blue'></Icon.InfoLg></Link>
                 {/*  </div>
                  <div className="d-flex align-items-center justify-content-center mb-1"> */}
                   &nbsp;<button className="btn btn-outline-primary producto-agregar" style={{ visibility: estadoV }} id={`${producto.id}`} onClick={agregarAlCarrito}><Icon.Cart4 size={24} color='blue'></Icon.Cart4></button>
                  </div>
                </div>
              </div>
            </div>
          )
        })
      }</div>
  </div>)
}

export default App;
