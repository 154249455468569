
import data from "../helps/output.json";
/* const numerito = document.querySelector("#numerito"); */
let botones_agregar = document.querySelectorAll(".producto-agregar");
let botonesEliminar = document.querySelectorAll(".carrito-producto-eliminar");
const productosEnCarrito = JSON.parse(localStorage.getItem("rounded-circle")) || [];
export function actualizarBotonesAgregar() {

    botones_agregar = document.querySelectorAll(".producto-agregar");

    botones_agregar.forEach(button => {
        button.addEventListener("click", (e) => {
            agregarAlCarrito(e);
   /*          actualizarNumeroce(); */

        });
    });
}

export function actualizarNumeroce() {
    const productosEnCarrito = JSON.parse(localStorage.getItem("rounded-circle")) || [];
    let numeroc = productosEnCarrito.reduce((acc, producto) => acc + producto.cantidad, 0);
    return Number(numeroc) == null ? 0 : Number(numeroc);
}

export function agregarAlCarrito(e) {
    const idBoton = e.currentTarget.id;
    const productoAgregado = data.find((producto) => producto.id === String(idBoton));
/*    */

    if (productosEnCarrito.some((producto) => producto.id ===  String(idBoton))) {
        /* const index = productosEnCarrito.findIndex((producto) =>  producto.id ===  Number(idBoton));
        productosEnCarrito[index].cantidad++; */
    }
    else {

        productoAgregado.cantidad = 1;
        productosEnCarrito.push(productoAgregado);
        localStorage.setItem("rounded-circle", JSON.stringify(productosEnCarrito));
        window.location.reload(true); 
        /* actualizarNumeroce(); */
 
    }
   

/*     productosEnCarrito = [];
    productosEnCarrito = JSON.parse(localStorage.getItem("rounded-circle")); */
}

export function agregarAlCarritoDetalle(e) {
    const idBoton = e.currentTarget.id;
    const productoAgregado = data.find((producto) => producto.id === String(idBoton));
/*    */

    if (productosEnCarrito.some((producto) => producto.id ===  String(idBoton))) {
        /* const index = productosEnCarrito.findIndex((producto) =>  producto.id ===  Number(idBoton));
        productosEnCarrito[index].cantidad++; */
    }
    else {

        productoAgregado.cantidad = 1;
        productosEnCarrito.push(productoAgregado);
        localStorage.setItem("rounded-circle", JSON.stringify(productosEnCarrito));
       
/*         actualizarNumeroc(); */
       /*  actualizarNumeroce(); */

    }

    const urlCart = '../cart';
    var win = window.open(urlCart, '_parent');
    win.focus();
}





export function actualizarBotonesElininar(){
    botonesEliminar = document.querySelectorAll(".carrito-producto-eliminar");
    botonesEliminar.forEach(button => {
        button.addEventListener("click", (e) => {
        eliminarDelCarrito(e);
        actualizarNumeroce();
    })
})
}



export function eliminarDelCarrito(e){
    const idBoton = e.currentTarget.id;
    const index = productosEnCarrito.findIndex((producto) => producto.id === String(idBoton));
    productosEnCarrito.splice(index, 1);
    localStorage.setItem("rounded-circle", JSON.stringify(productosEnCarrito));
   /*  actualizarNumeroce(); */
/*     productosEnCarrito = [];
    productosEnCarrito = JSON.parse(localStorage.getItem("rounded-circle")); */
    window.location.reload(true);  
}
