import { useEffect, useState } from 'react';
import '../App.css';
import data from "../data/categorias.json";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';
function App() {

/*     let productos = []; */
const [productos, setProductos] = useState([]);
  const pedirproducto = () => {
    return new Promise((resolve, reject)=>{
        resolve(data);
    })
  }
useEffect(()=>{
    pedirproducto()
    .then((res)=>{
      /* productos = res; */
      setProductos(res);
    })
},[])

return (
<div className="container-fluid pt-5">
<h2 className="text-uppercase mx-xl-5 mb-4"><span className="bg-secondary">Categorías</span></h2>
<div className="row px-xl-5 pb-3">{
productos.length > 0 &&
productos.map((producto, i)=>{

    return (<div className="col-lg-3 col-md-4 col-sm-6 pb-1" key={i}>
                  <Link  key={`link_${producto.id}`} className="text-decoration-none" to={producto.url}>
                      <div className="cat-cat d-flex align-items-center mb-4">
                          <div className="overflow-hidden" style={{width: '100px', height: '100px'}}>
                              <LazyLoadImage key={`imagen_${producto.id}`} className="img-fluid" src={`${producto.imagen}`} alt=""/>
                          </div>
                          <div className="flex-fill pl-3">
                              <h6  key={`titulo_${producto.id}`}>{producto.titulo}</h6>
                              <small  key={`descripcion_${producto.id}`} className="text-body">{producto.descripcion}</small>
                          </div>
                      </div>
                  </Link>    
              </div>)
})

}</div></div>)

}

export default App;